import React from "react";
import { connect } from "react-redux";
import loadable from "@loadable/component";

const ScrollableIcon = loadable(() => import("./components/ScrollableIcon"));

function SimpleContent({ index, context = [], breadcrumb = [] }) {
  if (!context.length) {
    return <></>;
  }

  return (
    <section className="uk-section uk-section-default">
      <div className="uk-container uk-container-expand">
        <div data-uk-grid="">
          <div className="uk-width-1-4@s">
            <ScrollableIcon breadcrumb={breadcrumb} index={index} />
          </div>
          <div
            className="uk-width-expand"
            dangerouslySetInnerHTML={{ __html: context[0] }}
          />
        </div>
      </div>
    </section>
  );
}

function mapStateToProps({ breadcrumb }) {
  return { breadcrumb };
}

export default connect(mapStateToProps)(SimpleContent);
